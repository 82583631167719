import styled from '@emotion/styled'
import { radialGradient } from 'polished'

import { Button } from '@components/core/buttons'
import { mediaQueries } from '@lib/mediaQueries'

export const NotFoundHeading = styled.div(
  {
    marginBottom: '2rem',
    textAlign: 'center',
  },
  ({ theme }) => ({
    color: theme.colors.white,
  }),
)

export const NotFoundHeader = styled.h1(
  {
    fontSize: '1.5rem',
    margin: '0 0 30px',
    textAlign: 'center',
  },
  ({ theme }) => ({
    color: theme.colors.text.headline,
  }),
)

export const NotFoundBackground = styled.div({
  bottom: 0,
  height: '100vh',
  maxHeight: '100vh',
  overflowY: 'hidden',
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  width: '100vw',

  '&:after': {
    ...radialGradient({
      colorStops: [
        '100.75% 50% at 38.61% 50%',
        '#608CFF 0%',
        ' rgba(169, 193, 255, 0) 99.98%',
        'rgba(220, 211, 255, 0) 99.99%',
      ],
    }),
    content: '""',
    display: 'block',
    filter: 'blur(150px)',
    height: '1211.87px',
    position: 'absolute',
    top: '50%',
    transform: 'rotate(49.91deg) translateY(-70%)',
    width: '311.14px',
    zIndex: 0,
  },
})

export const NotFoundContainer = styled.div(
  mediaQueries({
    alignContent: 'center',
    display: 'flex',
    height: '100vh',
    margin: 'auto',
    maxWidth: '400px',
    width: '100%',

    [`${Button}`]: {
      width: '100%',
    },
  }),
)

export const NotFoundText = styled.p(
  {
    lineHeight: 1.5,
    margin: '0 0 20px',

    a: {
      textDecoration: 'none',
    },
  },
  ({ theme }) => ({
    a: {
      color: theme.colors.primary,
    },
  }),
)

export const NotFoundLinks = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const NotFoundLink = styled.div(
  {
    '&:last-of-type': {
      a: {
        marginBottom: 0,
      },
    },
    a: {
      display: 'flex',
      margin: '0 0 10px',
      lineHeight: 1,
      textDecoration: 'none',
    },
  },
  ({ theme }) => ({
    a: {
      color: theme.colors.primary,
    },
  }),
)

export const ExternalLinkWrapper = styled.div({
  marginLeft: '3px',
})
