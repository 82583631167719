import { NextPage } from 'next'
import Head from 'next/head'
import { useEffect } from 'react'

import NotFoundComponent from '@components/notFound'
import Page from '@components/page'

const NotFoundPage: NextPage = () => {
  useEffect(() => {
    analytics.page('404 Not Found')
  }, [])

  return (
    <Page>
      <Head>
        <title>Portal | Page Not Found</title>
      </Head>

      <NotFoundComponent />
    </Page>
  )
}

export default NotFoundPage
