import Image from 'next/image'
import Link from 'next/link'
import externalLinkIcon from 'public/icons/icon-external-link.svg'
import { FunctionComponent } from 'react'

import { Card } from '@components/core/card'
import { PortalIcon } from '@components/core/icons'
import { PageBreakSmall } from '@components/core/pageBreak'

import {
  ExternalLinkWrapper,
  NotFoundBackground,
  NotFoundContainer,
  NotFoundHeader,
  NotFoundHeading,
  NotFoundLink,
  NotFoundLinks,
  NotFoundText,
} from './style'

const NotFound: FunctionComponent = () => {
  return (
    <>
      <NotFoundContainer>
        <Card
          color="white"
          style={{
            boxShadow: `200px -80px 150px rgba(107, 252, 243, 0.15), -80px 150px 150px rgba(255, 157, 245, 0.2), -20px -20px 70px rgba(97, 179, 255, 0.3), 20px 20px 70px rgba(164, 146, 255, 0.3)`,
            margin: 'auto',
            padding: '2.5rem',
          }}
        >
          <NotFoundHeading>
            <PortalIcon />
          </NotFoundHeading>

          <NotFoundHeader>Page Not Found</NotFoundHeader>

          <NotFoundText>Oops! That page does not exist.</NotFoundText>

          <PageBreakSmall />

          <NotFoundLinks>
            <NotFoundLink>
              <Link href="/login" passHref legacyBehavior>
                <a>
                  Go to Portal&apos;s dashboard{' '}
                  <ExternalLinkWrapper>
                    <Image
                      alt="External link"
                      height={17}
                      src={externalLinkIcon}
                      width={17}
                    />
                  </ExternalLinkWrapper>
                </a>
              </Link>
            </NotFoundLink>

            <NotFoundLink>
              <Link href="https://portalhq.io" passHref legacyBehavior>
                <a>
                  Go to Portal&apos;s website{' '}
                  <ExternalLinkWrapper>
                    <Image
                      alt="External link"
                      height={17}
                      src={externalLinkIcon}
                      width={17}
                    />
                  </ExternalLinkWrapper>
                </a>
              </Link>
            </NotFoundLink>

            <NotFoundLink>
              <Link href="https://docs.portalhq.io" passHref legacyBehavior>
                <a>
                  Go to Portal&apos;s SDK documentation{' '}
                  <ExternalLinkWrapper>
                    <Image
                      alt="External link"
                      height={17}
                      src={externalLinkIcon}
                      width={17}
                    />
                  </ExternalLinkWrapper>
                </a>
              </Link>
            </NotFoundLink>
          </NotFoundLinks>
        </Card>
      </NotFoundContainer>
      <NotFoundBackground />
    </>
  )
}

export default NotFound
